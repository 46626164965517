<template>
  <div>
    <Modal v-model="addModal" width="70%" footer-hide>
      <p slot="header" class="text-center">{{ planTypeName }}-添加施工任务</p>
      <div>
        <Row :gutter="8" class="m-b-10">
          <i-col span="3">
            <DatePicker type="daterange" placeholder="选择起止日期" size="small" transfer style="width: 100%"
              @on-change="onChangeDates"></DatePicker>
          </i-col>
          <i-col span="3" v-if="[1, 3].includes(planType)">
            <Select size="small" placeholder="线路" v-model="query.assetId" transfer clearable @on-change="onChangeAsset">
              <i-option v-for="item in assetArray" :key="'aid_' + item.id" :value="item.id">
                {{ item.name }}
              </i-option>
            </Select>
          </i-col>
          <i-col span="3" v-if="[1, 3].includes(planType)">
            <Select size="small" placeholder="站点" v-model="query.stationId" transfer clearable>
              <i-option v-for="item in stationArray" :key="'aid_' + item.id" :value="item.id">
                {{ item.name }}
              </i-option>
            </Select>
          </i-col>
          <i-col span="4">
            <i-input v-model.trim="query.keyword" size="small" placeholder="关键字：编号"></i-input>
          </i-col>
          <i-col span="4">
            <Button type="primary" icon="ios-search" size="small" class="m-r-5" @click="onSearch">搜索</Button>
          </i-col>
        </Row>
        <div class="m-b-10">
          <Button type="success" size="small" :disabled="btnSubmit" @click="onConfirm">确认添加</Button>
        </div>
        <div>
          <Table stripe max-height="620" size="small" :data="tableList" :columns="tableColumns" :loading="tableLoading"
            @on-selection-change="selectionChange"></Table>
          <div class="paging_style">
            <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator show-sizer
              :page-size-opts="pagesizeSetting" @on-page-size-change='onChangePageSize' :current="query.pageNumber"
              @on-change="onChangePageNum"></Page>
          </div>
        </div>
      </div>
      <!-- <div slot="footer">
        <Button type="text" class="m-r-5" @click="addModal = false">取消</Button>
        <Button type="primary" :disabled="btnSubmit" @click="onConfirm">确认添加</Button>
      </div> -->
    </Modal>
  </div>
</template>

<script>
import { indexMixins } from '@/components/constructionApply/mixins'
import { getTaskitemList, addTaskToWorkday, getTrainTaskitemList, addTaskToExitWorkday } from '@/api/msp/workdaySchedule'
// import { addWorkdayToTable } from '@/api/msp/workdayTable'
export default {
  props: {
    tId: {
      type: Number,
      required: true
    },
    workdayId: {
      type: Number
    },
    planType: {
      type: Number,
      default: 1
    },
    onSuccess: {
      type: Function,
      default: () => { }
    }
  },
  mixins: [indexMixins],
  data () {
    return {
      addModal: false,
      planTypeName: '车站计划',
      tableId: null,
      assetArray: [],
      stationArray: [],
      query: {
        pageNumber: 1,
        pageSize: 15,
        startDate: '',
        endDate: '',
        assetId: null,
        stationId: null,
        taskitemType: null,
        keyword: ''
      },
      selectedId: [], // 选中项id
      tableList: [],
      total: 0,
      tableLoading: false,
      tableColumns: [],
      typeShowColumns: new Map([
        [1, ['selection', 'actionDate', 'typeName', 'advertiserName', 'brandName', 'assetName', 'stationName', 'positionName', 'deviceName', 'code', 'workName', 'createCompanyName', 'supplierCompanyName', 'remark']], // 车站计划列表
        [2, ['selection', 'actionDate', 'typeName', 'advertiserName', 'brandName', 'deviceName', 'code', 'workName', 'createCompanyName', 'supplierCompanyName', 'remark']], // 车场计划
        [3, ['selection', 'actionDate', 'typeName', 'advertiserName', 'brandName', 'assetName', 'stationName', 'positionName', 'deviceName', 'code', 'workName', 'createCompanyName', 'supplierCompanyName', 'remark']] // 临时计划
      ])
    }
  },
  computed: {
    btnSubmit () {
      return this.selectedId.length === 0
    }
  },
  methods: {
    showModal () {
      this.tableId = this.tId
      this.query.pageNumber = 1
      this.query.startDate = ''
      this.query.endDate = ''
      this.query.assetId = null
      this.query.stationId = null
      this.query.keyword = ''
      this.getTableColumns()
      if ([1, 3].includes(this.planType)) { // 车站、临时计划
        this.getAssets()
      }

      switch (this.planType) {
        case 1:
          this.planTypeName = '车站计划'
          break
        case 2:
          this.planTypeName = '车场计划'
          break
        case 3:
          this.planTypeName = '临时计划'
          break

        default:
          break
      }

      this.getTableData()
      this.addModal = true
    },
    getTableColumns () {
      const defineColumns = {
        selection: { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        actionDate: { title: '作业日期', align: 'center', minWidth: 100, key: 'actionDate' },
        typeName: { title: '任务类型', align: 'center', minWidth: 100, key: 'typeName' },
        advertiserName: { title: '客户名称', align: 'center', minWidth: 120, key: 'advertiserName' },
        brandName: { title: '品牌名', align: 'center', minWidth: 120, key: 'brandName' },
        assetName: { title: '线路', align: 'center', minWidth: 120, key: 'assetName' },
        stationName: { title: '站点', align: 'center', minWidth: 120, key: 'stationName' },
        positionName: {
          title: '位置',
          align: 'center',
          minWidth: 100,
          render: (h, { row }) => {
            return h('span', `${row.floor}层 - ${row.positionName}`)
          }
        },
        deviceName: { title: '资源类型', align: 'center', minWidth: 120, key: 'deviceName' },
        code: { title: '资源编号', align: 'center', minWidth: 120, key: 'code' },
        workName: { title: '作业名称', align: 'center', minWidth: 120, key: 'name' },
        createCompanyName: { title: '任务发起方', align: 'center', minWidth: 120, key: 'createCompanyName' },
        supplierCompanyName: { title: '施工单位', align: 'center', minWidth: 120, key: 'supplierCompanyName' },
        remark: { title: '备注', align: 'center', minWidth: 120, key: 'remark' }
      }

      const data = []
      var showColumns = this.typeShowColumns.get(this.planType)
      if (!showColumns || showColumns.length === 0) {
        showColumns = this.typeShowColumns.get(1)
      }
      showColumns.forEach(col => data.push(defineColumns[col]))

      this.tableColumns = data
    },
    async getTableData () {
      this.tableLoading = true
      let result = null
      if ([1, 3].includes(this.planType)) {
        // 车站、临时计划
        result = await getTaskitemList(this.query).finally(() => { this.tableLoading = false })
      } else {
        // 车场计划
        result = await getTrainTaskitemList(this.query).finally(() => { this.tableLoading = false })
      }

      if (result && !result.errcode) {
        this.tableList = result.list
        this.total = result.totalRow
      }
    },
    async getAssets () {
      this.assetArray = await this.getAssetData()
    },
    async onChangeAsset () {
      this.stationArray = await this.getStationData(this.query.assetId)
    },
    onChangeDates (dates) {
      this.query.startDate = dates[0]
      this.query.endDate = dates[1]
    },
    onChangePageNum (curPage) {
      this.selectedId = []
      this.query.pageNumber = curPage
      this.getTableData()
    },
    onChangePageSize (curPageSize) {
      this.query.pageNumber = 1
      this.query.pageSize = curPageSize
      this.getTableData()
    },
    onSearch () {
      this.query.pageNumber = 1
      this.selectedId = []
      this.getTableData()
    },
    selectionChange (selections) {
      this.selectedId = selections.map(x => x.id)
    },
    onConfirm () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认信息无误，并提交？',
        onOk: async () => {
          let result = null
          if ([1, 3].includes(this.planType)) {
            // 车站、临时计划
            const postData = {
              tableId: this.tId,
              taskitemIds: JSON.stringify(this.selectedId)
            }
            result = await addTaskToWorkday(postData)
          } else {
            // 车场计划
            const postData = {
              workdayId: this.workdayId,
              taskitemIds: JSON.stringify(this.selectedId)
            }
            result = await addTaskToExitWorkday(postData)
          }

          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.selectedId = []
            this.addModal = false
            this.onSuccess()
          }
        }
      })
    }
  }
}
</script>
